export default {
  blogerName: 'BRO',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/brat737',
    }
  ],
  projects: [
    {
      name: 'gizbo',
      url: 'http://gizbo-bro.com/cd50a23b1',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 600FS</strong><div>На первый депозит</div>',
    },
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c63148ffb',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 600FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c3cab70b0',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-blrs10.com/c2c8e82ec',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/cec2d58b6',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c31eeef56',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cefee0ac9',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cec027cf3',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c88a66a78',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cb3723197',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c024a2f6e',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c98888a26',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c43278879',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>BRO</strong> и получи 125 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'BRO',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>125FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
